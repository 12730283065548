html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  background-color: rgb(231, 243, 243);
  margin: 0px;
  border: 0px;
  border-color: darkgreen;
  height: auto;
  border-left: 0px;
  border-left-width: 0px;
  padding: 0px; 
  box-sizing: border-box;

}

* {
  box-sizing: border-box;
}

#root {
  padding: 0;
  border: none;
  margin:0;
}

.container{
width: 100vw;  
margin: 80px auto 0 auto;  
max-width: 1200px;
box-sizing: border-box;
}

.App {
  width: 100%;
  
  border: 0;
  padding: 0;
  margin:0;
  border-width: 0;
}

.nav-container{
  margin: auto;
}

.nav-container svg {
  color: #fff;
}
a{
  text-decoration: none;
}